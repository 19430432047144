<template>
  <div class="file-remover">
    <a-modal v-model="visible" title="Delete file" :zIndex="9999">
      <p>Deleted files cannot be retrieved. Do you want to delete them completely?</p>
      <template slot="footer">
        <a-space>
          <a-button @click="() => visible = false">Cancel</a-button>
          <a-button type="danger" @click="handleRemove">Completely cancel</a-button>
        </a-space>
      </template>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'file-remover',
  data() {
    return {
      visible: false,
      callback: undefined,
      file: undefined
    }
  },
  methods: {
    open(file, callback) {
      this.file = file
      this.callback = callback
      this.visible = true
    },
    close() {
      this.visible = false
    },
    handleRemove() {
      const { type } = this.file
      if (type === 'file') {
        this.$store.dispatch('file/removeFile', this.file).then(() => {
          this.callback && this.callback()
        })
      }
      else {
        this.$store.dispatch('file/removeFolder', this.file).then(() => {
          this.callback && this.callback()
        })
      }
    }
  }
}
</script>

<style scoped lang="less">

</style>
