<template>
  <div class="bin-file-viewer">
    <a-modal v-model="visible" :footer="null" :width="608">
      <a-descriptions bordered :column="6">
        <a-descriptions-item label="File name" :span="6">
          <div :title="file.name" class="file-name">
            {{ file.name }}
          </div>
        </a-descriptions-item>
        <a-descriptions-item label="File type" :span="6">
          {{ file.type | typeFilter }}
        </a-descriptions-item>
        <a-descriptions-item label="Delete date" :span="6">
          {{ file.deleteTime }}
        </a-descriptions-item>
        <a-descriptions-item label="Operate" :span="6">
          <a-space>
            <a @click="handleRecover">Recover</a>
              <a href="#" @click="handleDelete">Completely cancel</a>
          </a-space>
        </a-descriptions-item>
      </a-descriptions>
      <template slot="title">
        <p class="title">{{ file.name + ' - ' + 'detailed information'}}</p>
      </template>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'bin-file-viewer',
  data() {
    return {
      file: {},
      visible: false
    }
  },
  methods: {
    open(file) {
      this.file = file
      this.visible = true
    },
    close() {
      this.visible = false
    },
    handleRecover() {
      const payload = this.file
      this.$emit('recover', payload)
    },
    handleDelete() {
      const { type, id } = this.file
      const payload = {
        id,
        type,
        isPhysical: true
      }
      this.$emit('delete', payload)
    }
  },
  filters: {
    typeFilter(type) {
      if (type === 'file') {
        return 'document'
      } else {
        return 'file'
      }
    },
  }
}
</script>

<style scoped lang="less">
.title {
  width: 92%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-name {
  width: 360px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
